<template>
  <div
    style="position: relative; max-height: 100%"
    id="scroll-target"
    class="overflow-y-auto"
  >
    <v-slide-y-transition>
      <v-btn
        z-index="1000"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        small
        color="primary"
        style="position: fixed; bottom: 20px; right: 20px; z-index: 999"
        @click="$vuetify.goTo(0)"
      >
        <v-icon>mdi-arrow-up-bold-circle</v-icon>
      </v-btn>
    </v-slide-y-transition>

    <v-container v-scroll:#scroll-target="onScroll">
      <div class="d-flex align-center mb-3">
        <v-btn depressed :disabled="loadingSubmit" @click="goBack">
          <v-icon class="mr-2" medium>mdi-arrow-left</v-icon
          >{{
            $route.query.id
              ? isApplicant
                ? $t("app.student_detail")
                : $t("master_data.student.edit_student")
              : $t("ppdb.student.add_manual")
          }}
        </v-btn>
        <v-spacer />

        <!-- <v-btn
          depressed
          class="mr-2"
          color="primary"
          @click="downloadPdf($route.query)"
        >
          <span class="mr-2">{{ $t("app.download") }}</span>
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn> -->

        <div v-if="!isApplicant">
          <v-btn
            v-if="!isReadOnly && $route.query.id"
            :disabled="loadingSubmit"
            class="mr-3 gradient-error caption"
            dark
            depressed
            @click="cancel()"
          >
            <span v-if="!$vuetify.breakpoint.xsOnly" class="mr-2">{{
              $t("app.cancel")
            }}</span>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-btn
            v-if="$route.query.id || $route.query.view == true"
            :color="isReadOnly ? 'gradient-primary-dark' : 'gradient-primary'"
            :loading="loadingSubmit"
            class="error mr-3 caption"
            depressed
            dark
            @click="saveEdit()"
          >
            <span v-if="!$vuetify.breakpoint.xsOnly" class="mr-2">{{
              isReadOnly ? $t("app.edit") : $t("app.save")
            }}</span>
            <v-icon v-if="isReadOnly">mdi-square-edit-outline</v-icon>
            <v-icon v-if="!isReadOnly">mdi-check-circle</v-icon>
          </v-btn>
        </div>
      </div>
      <v-stepper v-model="stepPosition" :non-linear="isReadOnly">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepPosition > 1"
            :rules="[() => g_ppdb_siswa.completed]"
            :editable="isReadOnly"
            step="1"
            >{{ $t("ppdb.student.tab1") }}</v-stepper-step
          >
          <v-divider />
          <v-stepper-step
            :complete="stepPosition > 2"
            :rules="[() => g_ppdb_ortu.completed]"
            :editable="isReadOnly"
            step="2"
            >{{ $t("ppdb.student.tab2") }}</v-stepper-step
          >
          <v-divider />
          <v-stepper-step
            :rules="[() => g_ppdb_wali.completed]"
            :editable="isReadOnly"
            step="3"
            >{{ $t("ppdb.student.tab3") }}</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="siswa" lazy-validation>
              <div class="mb-3 d-flex justify-center" v-if="loading">
                <v-progress-circular size="30" color="primary" indeterminate />
              </div>
              <div class="mb-3" v-else>
                <Student
                  :isEdit="isReadOnly"
                  :isApplicant="isApplicant"
                  :is-valid="formValidation"
                />
              </div>

              <v-row no-gutters class="mb-5 ml-3">
                <v-btn
                  dark
                  depressed
                  class="gradient-primary-dark"
                  @click="nextStep(1, 'siswa')"
                >
                  {{ $t("app.next") }}
                </v-btn>
                <v-spacer />
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="orang_tua">
              <div class="mb-3">
                <Parents :is-valid="formValidation" :isEdit="isReadOnly" />
              </div>

              <v-row no-gutters class="mb-5 ml-3">
                <v-btn outlined color="primary" @click="prevStep(2)">{{
                  $t("app.back")
                }}</v-btn>
                <v-btn
                  dark
                  depressed
                  class="gradient-primary-dark ml-2"
                  @click="nextStep(2, 'orang_tua')"
                >
                  {{ $t("app.next") }}
                </v-btn>
                <v-spacer />
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form ref="wali" lazy-validation>
              <div class="mb-3">
                <Guardian :is-valid="formValidation" :isEdit="isReadOnly" />
              </div>

              <v-row no-gutters class="mb-5">
                <v-btn color="primary" outlined @click="prevStep(3)">{{
                  $t("app.back")
                }}</v-btn>
                <v-btn
                  v-if="!$route.query.id ? true : false"
                  dark
                  depressed
                  class="gradient-primary-dark ml-3"
                  @click="save('wali')"
                >
                  {{ $t("app.save") }}
                </v-btn>
                <v-spacer />
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <vue-html2pdf
        ref="detailStudent"
        :htmlToPdfOptions="pdfOptions()"
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-content-width="97%"
        @progress="onProgress($event)"
      >
        <section slot="pdf-content">
          <StudentDetailDownload :dataPdf="dataPdf" />
        </section>
      </vue-html2pdf>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isFormValid } from "@/utils/validate";
import { Student, Parents, Guardian } from "@/views/ppdb/components";
import { getByID, studentUpdate } from "@/api/admin/master/student";
import { getApplicantByID } from "@/api/admin/master/applicant";

import StudentDetailDownload from "./StudentDetailDownload";
import i18n from "@/i18n";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    Student,
    Parents,
    Guardian,
    StudentDetailDownload,
    VueHtml2pdf
  },
  computed: mapGetters([
    "g_ppdbsiswa",
    "g_ppdb_siswa",
    "g_ppdb_ortu",
    "g_ppdb_wali",
    "g_ppdb_pernyataan"
  ]),
  data() {
    return {
      fab: false,
      isApplicant: this.$route.query.applicant,
      stepPosition: 1,
      steps: 3,
      siswaRules: false,
      loadingSubmit: false,
      queryData: {
        type: "STUDENT",
        limit: 10,
        status: "ACCEPTED"
      },
      loading: false,
      isReadOnly: this.$route.query.id ? true : false,
      downloadDialog: false,
      dataPdf: null,
      selectedDownloadPdf: null
    };
  },
  watch: {
    steps(val) {
      if (this.stepPosition > val) {
        this.stepPosition = val;
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.initData();
    }
    this.$store.dispatch("getMasterData");
  },
  destroyed() {
    this.$store.commit("RESET_PPDB_SISWA");
  },
  methods: {
    onProgress(e) {
      if (e == 100) this.$store.commit("TOGGLE_FULL_LOADING");
    },
    async downloadPdf() {
      this.$store.commit("TOGGLE_FULL_LOADING");
      this.selectedDownloadPdf = this.g_ppdbsiswa;
      this.dataPdf = this.g_ppdbsiswa;

      setTimeout(async () => {
        await this.$refs.detailStudent.generatePdf();
        this.selectedDownloadPdf = null;
        this.dataPdf = null;
      }, 100);
    },
    pdfOptions() {
      const filename = this.selectedDownloadPdf
        ? `${this.selectedDownloadPdf.siswa.data_diri.nama_lengkap}-${this.selectedDownloadPdf.student.nis}.pdf`
        : "";

      return {
        margin: 0.3,
        filename: filename,
        image: {
          type: "jpeg",
          quality: 0.98
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true,
          dpi: 96
        },
        jsPDF: {
          unit: "cm",
          format: "a4",
          orientation: "portrait"
        }
      };
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 150;
    },
    save(tab) {
      setTimeout(() => {
        if (this.$refs[tab].validate()) {
          this.$store.commit("TOGGLE_FULL_LOADING");
          this.loadingSubmit = true;
          this.$store.commit("AUTO_ACCEPT_STUDENT");
          this.$store.commit("SET_COMPLETE_TAB", {
            status: true,
            param: tab
          });

          this.$store
            .dispatch("insertPpdbSiswa", this.g_ppdbsiswa)
            .then(res => {
              this.loadingSubmit = false;
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t(
                    "master_data.student.response.success_add_student"
                  ),
                  color: "success"
                });
                this.goBack();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.$store.commit("TOGGLE_FULL_LOADING");
            })
            .catch(() => {
              this.$store.commit("TOGGLE_FULL_LOADING");
              this.loadingSubmit = false;
            });
        } else {
          this.$store.commit("SET_COMPLETE_TAB", {
            status: false,
            param: tab
          });
          this.$vuetify.goTo(140);
        }
      }, 200);
    },
    saveEdit() {
      if (this.isReadOnly) {
        this.isReadOnly = false;
        this.stepPosition = 1;
      } else {
        const isValidate = ["siswa", "orang_tua", "wali"].map(d => {
          if (!this.$refs[d].validate()) {
            this.$store.commit("SET_COMPLETE_TAB", {
              status: false,
              param: d
            });
            this.$vuetify.goTo(140);
            return false;
          }
          return true;
        });

        const valid = isValidate.includes(false);
        if (!valid) {
          this.loadingSubmit = true;
          const postEdit = {
            student: this.g_ppdbsiswa.student,
            siswa: {
              data_diri: this.g_ppdbsiswa.siswa.data_diri,
              alamat_siswa: this.g_ppdbsiswa.siswa.alamat_siswa,
              data_keluarga: this.g_ppdbsiswa.siswa.data_keluarga,
              data_kesehatan: this.g_ppdbsiswa.siswa.data_kesehatan,
              pendidikan_sebelumnya: this.g_ppdbsiswa.siswa
                .pendidikan_sebelumnya,
              orang_tua: {
                data_ayah: this.g_ppdbsiswa.orang_tua.data_ayah,
                alamat_ayah: this.g_ppdbsiswa.orang_tua.alamat_ayah,
                data_ibu: this.g_ppdbsiswa.orang_tua.data_ibu,
                alamat_ibu: this.g_ppdbsiswa.orang_tua.alamat_ibu,
                wali: {
                  isOrtu: this.g_ppdbsiswa.wali.isOrtu,
                  isFatherOrMother: this.g_ppdbsiswa.wali.isFatherOrMother,
                  data_wali: this.g_ppdbsiswa.wali.data_wali,
                  alamat_wali: this.g_ppdbsiswa.wali.alamat_wali
                }
              }
            }
          };

          studentUpdate(postEdit)
            .then(res => {
              this.loadingSubmit = false;
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t(
                    "master_data.student.response.success_edit_student"
                  ),
                  color: "success"
                });
                this.goBack();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        }
      }
    },
    goBack() {
      let tabs = 0;
      if (!this.$route.query.id || this.$route.query.applicant) {
        tabs = 2;
      }

      this.$router.push({
        path: "/master/student",
        query: {
          tab: tabs,
          year: this.$route.query.year,
          grade: this.$route.query.grade,
          class: this.$route.query.class
        }
      });
      this.$store.commit("RESET_PPDB_SISWA");
    },
    cancel() {
      this.isReadOnly = true;
      this.initData();
    },
    initData() {
      this.loading = true;
      return new Promise(resolve => {
        if (this.isApplicant) {
          getApplicantByID({ id: this.$route.query.id }).then(d => {
            if (d) {
              let tempData = d.data.data;
              tempData.isApplicant = true;
              this.$store.commit("SET_DATA_STUDENT", tempData);
            }
            this.loading = false;
            resolve(d);
          });
        } else {
          getByID({ id: this.$route.query.id }).then(d => {
            if (d) this.$store.commit("SET_DATA_STUDENT", d.data.data);
            this.loading = false;
            resolve(d);
          });
        }
      });
    },
    closeModalRegisterDone() {
      this.modalRegisterDone = false;
    },
    formValidation(text, type) {
      return isFormValid(text, type);
    },
    nextStep(n, tab) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        switch (tab) {
          case "siswa":
            this.$store.commit("SET_PANEL", { tab: "siswa", val: [0, 1, 4] });
            break;
          default:
            this.$store.commit("SET_PANEL", { tab: "wali", val: [0] });
            break;
        }

        setTimeout(() => {
          if (this.$refs[tab].validate()) {
            this.stepPosition = n + 1;
            this.$store.commit("SET_COMPLETE_TAB", {
              status: true,
              param: tab
            });
            this.$vuetify.goTo(140);
          } else {
            this.$store.commit("SET_COMPLETE_TAB", {
              status: false,
              param: tab
            });
            this.$nextTick(() => {
              const element = document.querySelector(".v-input.error--text");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "center"
                });
              }
            });
          }
        }, 200);
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    }
  }
};
</script>
